import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout
      pageTitle="About"
      pageAuthor={"Chase Buhler"}
      >
        <div className='page-max-container'>
          <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
        </div>
    </Layout>
  )
}

export default AboutPage